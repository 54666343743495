import { DialogIdent, useDialogStore } from '@/stores/useDialogStore';
import type { Address } from '~/server/transformers/shop/addressTransformer';
import type { AddressType } from '~/stores/useUserAddress';

export default function useNewEditAddressDialog() {
  const dialogStore = useDialogStore();
  type openOptions = {
    address?: Address;
    addressType?: AddressType;
  };
  return {
    open: async (options: openOptions) => {
      return dialogStore.openDialog(DialogIdent.NEW_EDIT_ADDRESS, options);
    },
    getData: () => {
      return dialogStore.getDialogData<openOptions>(
        DialogIdent.NEW_EDIT_ADDRESS,
      );
    },
    close: (data?: any) => {
      dialogStore.closeDialog(data);
    },
    setHeadline: (headline: string) => dialogStore.setHeadline(headline),
  };
}
