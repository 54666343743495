<template>
  <NotificationBar
    v-if="asNotification"
    :type="NotificationType.WARN"
    :description="t('user.account.addresses.defaultShippingInfo')"
  />
  <div v-else class="flex gap-2xs">
    <Icon
      icon-class="fad fa-circle-exclamation"
      class="shrink-0 mt-2xs"
      style="
        --fa-primary-color: var(--thm-text-base);
        --fa-secondary-color: var(--thm-status-warn-base);
      "
    />
    <div class="text-sm text-border-dark">
      {{ t('user.account.addresses.defaultShippingInfo') }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { NotificationBar, NotificationType, Icon } from '@/complib';

defineProps({
  asNotification: {
    type: Boolean,
    default: false,
  },
});
const { t } = useTrans();
</script>
